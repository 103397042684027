import { createSlice } from '@reduxjs/toolkit';
import { IStatus } from '../../../@types/status';
import { getAllNotifications, getNotificationsCounts, markOneNotificationAsRead, marlAllNotificationsAsRead, } from './actions';
export var NOTIFICATION_FILTER;
(function (NOTIFICATION_FILTER) {
    NOTIFICATION_FILTER["NONE"] = "none";
    NOTIFICATION_FILTER["ALL"] = "all";
    NOTIFICATION_FILTER["READ"] = "read";
    NOTIFICATION_FILTER["UNREAD"] = "unread";
})(NOTIFICATION_FILTER || (NOTIFICATION_FILTER = {}));
var initialState = {
    unread: 0,
    notifications: { docs: [], meta: {} },
    status: IStatus.IDLE,
    filter: NOTIFICATION_FILTER.NONE,
};
var slice = createSlice({
    name: 'notifications',
    initialState: initialState,
    reducers: {},
    extraReducers: function (builder) {
        // GET ALL
        builder
            .addCase(getAllNotifications.pending, function (state, action) {
            state.status = IStatus.LOADING;
        })
            .addCase(getAllNotifications.fulfilled, function (state, action) {
            state.status = IStatus.SUCCEEDED;
            state.notifications = action.payload;
        })
            .addCase(getAllNotifications.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
        // GET UNREAD NOTIFICATIONS NUMBER
        builder
            .addCase(getNotificationsCounts.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(getNotificationsCounts.fulfilled, function (state, action) {
            state.status = IStatus.SUCCEEDED;
            state.unread = action.payload.unread;
        })
            .addCase(getNotificationsCounts.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
        // MARK ALL AS READ
        builder
            .addCase(marlAllNotificationsAsRead.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(marlAllNotificationsAsRead.fulfilled, function (state) {
            state.status = IStatus.SUCCEEDED;
            state.unread = 0;
        })
            .addCase(marlAllNotificationsAsRead.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
        // MARK ONE AS READ
        builder
            .addCase(markOneNotificationAsRead.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(markOneNotificationAsRead.fulfilled, function (state, action) {
            state.status = IStatus.SUCCEEDED;
            state.notifications.docs = state.notifications.docs.filter(function (notification) { return notification._id !== action.meta.arg; });
            state.unread -= 1;
        })
            .addCase(markOneNotificationAsRead.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
    },
});
export default slice.reducer;
